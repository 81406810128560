import React, { useEffect } from "react";
import QRCode from "qrcode.react";
import { useLocation } from "@reach/router";
import { checkBadge } from "store/actions/virtual-card.action";
import { VirtualCardReducerInterface } from "store/reducers/virtual-card.reducer";
import { Helmet } from "react-helmet";
import { useConfig } from "hooks/use-config.hook";
import { translationHelper } from "helpers/translation.helper";
import { useAppDispatch, useAppSelector } from "../../hooks/use-redux.hook";

const VirtualCard: React.FC = (props) => {
    const card_reducer: VirtualCardReducerInterface = useAppSelector(
        state => state.virtual_card,
    );

    const config = useConfig();

    const location = useLocation();

    const dispatch = useAppDispatch();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        dispatch(checkBadge(urlParams.get("id") || ""));
    }, [dispatch, location.search]);

    const { badge } = card_reducer;

    if (!card_reducer.isLoaded) {
        return (
            <div className="flex flex-col min-h-screen max-w-md">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="animate-spin-slow"
                >
                    <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M20 4v5h-.582m0 0a8.001 8.001 0 00-15.356 2m15.356-2H15M4 20v-5h.581m0 0a8.003 8.003 0 0015.357-2M4.581 15H9"
                    />
                </svg>
            </div>
        );
    }

    if ((card_reducer.isLoaded && card_reducer.isEmpty) || badge == null) {
        return (
            <div className="flex flex-col min-h-screen max-w-md justify-center items-center">
                <h1 className="text-3xl">Viallinen passitunniste</h1>
            </div>
        );
    }

    const styles = {
        backgroundImage:
            "url(" + badge.badge_type.mobile_badge.background_image_url + ")",
        background: badge.badge_type.mobile_badge.background_color,
    };

    return (
        <div className="flex flex-col min-h-screen max-w-md" style={styles}>
            <Helmet>
                <title>
                    Mobiilipassi - {translationHelper(config.title, "fi")}
                </title>
            </Helmet>
            <img
                src={badge.badge_type.mobile_badge.header_image_url}
                className=""
                alt=""
            />
            <div className="w-full flex justify-center text-center">
                <div className="block my-4">
                    <h5 className="text-xl">
                        {badge.holder_firstname} {badge.holder_lastname}
                    </h5>
                    <h5 className="text-xl mb-2">{badge.holder_title}</h5>
                    <div className="text-center">
                        <QRCode
                            renderAs="canvas"
                            value={badge.code}
                            fgColor="#2C2E35"
                            includeMargin={false}
                            size={128}
                            className="m-auto mb-2"
                        />
                        <h5 className="text-xl">{badge.code}</h5>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VirtualCard;
