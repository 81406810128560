import axios from "axios";
import { VirtualCardActionType } from "interfaces/virtual-card-action-type.enum";

/**
 * Checks given badge
 * @returns {Promise<*>} dispatches card result to {@link virtualCardReducer}
 * @param badge_id id of the badge
 */
export const checkBadge = (badge_id: string) => {
    return async (dispatch: any) => {
        try {
            const response = await axios.get(
                `${process.env.GATSBY_TICKET_API_URL}/badges/` + badge_id
            );
            return dispatch({
                type: VirtualCardActionType.BADGE_CHECK_SUCCESS,
                badge: response.data.badge,
            });
        } catch (error) {
            console.log(error);
            return dispatch({
                type: VirtualCardActionType.BADGE_CHECK_ERROR,
                error_message: error.message,
            });
        }
    };
};

/**
 * Checks given customer membership card
 * @returns {Promise<*>} dispatches card result to {@link virtualCardReducer}
 * @param card_id id of the card
 * @param pin of the card
 */
export const checkMembershipCard = (card_id: string, pin: string) => async (
    dispatch: any
) => {
    try {
        const response = await axios.get(
            `${process.env.GATSBY_CUSTOMER_MEMBERSHIP_API_URL}/v1/customer-membership-cards/` +
                card_id,
            {
                auth: {
                    username: card_id,
                    password: pin,
                },
            }
        );

        const d = new Date();
        d.setTime(d.getTime() + 360 * 24 * 60 * 60 * 1000);
        const expires = "expires=" + d.toUTCString();
        document.cookie = `customer_membership_${card_id}=${pin}; ${expires}; SameSite=Strict; Secure;`;

        return dispatch({
            type: VirtualCardActionType.CARD_CHECK_SUCCESS,
            customer_membership_card: response.data.customer_membership_card,
        });
    } catch (error) {
        if (error.response.status === 401) {
            console.log("requires auth");
            return dispatch({
                type: VirtualCardActionType.CARD_REQUIRES_AUTHENTICATION,
            });
        }
        if (error.response.status === 403) {
            deleteCardCookie(card_id);
            return dispatch({
                type: VirtualCardActionType.CARD_WRONG_PIN,
            });
        }
        if (error.response.status === 404) {
            return dispatch({
                type: VirtualCardActionType.CARD_CHECK_ERROR,
                error_message: error.message,
            });
        }
    }
};

export const deleteCardCookie = (card_id: string) => {
    document.cookie = `customer_membership_${card_id}=1; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=Strict; Secure;`;
};
